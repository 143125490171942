// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Bootstrap overrides ===================================
//

// Color system

$white: #ffffff !default;
$gray-100: #f9fbfd !default;
$gray-200: #f1f4f8 !default;
$gray-300: #d9e2ef !default;
$gray-400: #c6d3e6 !default;
$gray-500: #abbcd5 !default;
$gray-600: #869ab8 !default;
$gray-700: #506690 !default;
$gray-800: #384c74 !default;
$gray-900: #1b2a4e !default;
$black: #52596e !default;

$primary: #2d3f64 !default;
$primary-desat: #6c8aec !default; // NEW
$secondary: $gray-700 !default;
$success: #42ba96 !default;
$info: #7c69ef !default;
$warning: #fad776 !default;
$danger: #d65852 !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$theme-colors: (
  "primary": $primary,
  "primary-desat": $primary-desat,
  // NEW
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
) !default;

$primary-bg-subtle: rgba($primary, 0.1) !default;
$primary-desat-bg-subtle: rgba($primary-desat, 0.1) !default; // NEW
$secondary-bg-subtle: rgba($secondary, 0.1) !default;
$success-bg-subtle: rgba($success, 0.1) !default;
$info-bg-subtle: rgba($info, 0.1) !default;
$warning-bg-subtle: rgba($warning, 0.1) !default;
$danger-bg-subtle: rgba($danger, 0.1) !default;
$light-bg-subtle: rgba($light, 0.1) !default;
$dark-bg-subtle: rgba($dark, 0.1) !default;
$white-bg-subtle: rgba($white, 0.1) !default; // NEW
$gray-700-bg-subtle: rgba($gray-700, 0.1) !default; // NEW

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.75 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$color-contrast-dark: $gray-900 !default;
$color-contrast-light: $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true !default;
$enable-smooth-scroll: false !default;
$enable-rfs: false !default;
$enable-validation-icons: false !default;
$enable-negative-margins: true !default;
$enable-dark-mode: false !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  // 4px
  2:
    (
      $spacer * 0.5
    ),
  // 8px
  3:
    (
      $spacer * 0.75
    ),
  // 12px
  4: $spacer,
  // 16px
  5:
    (
      $spacer * 1.5
    ),
  // 24px
  6:
    (
      $spacer * 2
    ),
  // 32px
  7:
    (
      $spacer * 2.5
    ),
  // 40px
  8:
    (
      $spacer * 3
    ),
  // 48px
  9:
    (
      $spacer * 4
    ),
  // 64px
  10:
    (
      $spacer * 5
    ),
  // 80px
  11:
    (
      $spacer * 6
    ),
  // 96px
  12:
    (
      $spacer * 8
    ),
  // 128px
  13:
    (
      $spacer * 10
    ),
  // 160px
  14:
    (
      $spacer * 12
    ),
  // 192px
  15:
    (
      $spacer * 16
    ),
  // 256px
  16:
    (
      $spacer * 25
    ),
  // 400px
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $black !default;

$body-secondary-color: $gray-600 !default;

// Links
//
// Style anchor elements.

$link-decoration: none !default;
$link-hover-decoration: underline !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 2.5rem !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-200 !default;

$border-radius-sm: 0.25rem !default;
$border-radius: 0.375rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default; // NEW
$border-radius-pill: 50rem !default;

$box-shadow: 0 0.5rem 1.5rem fade-out($black, 0.9) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 1.5rem 4rem fade-out($black, 0.9) !default;

$box-shadow-light: 0 0.5rem 1.5rem fade-out($black, 0.95) !default;
$box-shadow-light-lg: 0 1.5rem 4rem fade-out($black, 0.95) !default;

$box-shadow-dark: 0 0.5rem 1.5rem fade-out($black, 0.85) !default;
$box-shadow-dark-lg: 0 1.5rem 4rem fade-out($black, 0.85) !default;

$box-shadow-lift:
  0 1rem 2.5rem fade-out($black, 0.9),
  0 0.5rem 1rem -0.75rem fade-out($black, 0.9) !default;
$box-shadow-lift-lg:
  0 2rem 5rem fade-out($black, 0.9),
  0 0.5rem 1rem -0.75rem fade-out($black, 0.95) !default;

$component-active-color: $white !default;
$component-active-bg: $primary !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base: "Lato", serif !default;

$font-size-base: 1.0625rem !default; // 17px
$font-size-xs: 0.75rem !default; // 12px NEW
$font-size-sm: 0.9375rem !default; // 15px
$font-size-lg: 1.1875rem !default; // 19px

$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 700 !default;

$line-height-base: 1.6 !default;
$line-height-sm: 1.3 !default;
$line-height-lg: 1.3 !default;

$h1-font-size: 2.3125rem !default; // 37px
$h2-font-size: 1.9375rem !default; // 31px
$h3-font-size: 1.3125rem !default; // 21px
$h4-font-size: 1.1875rem !default; // 19px
$h5-font-size: 1.0625rem !default; // 17px
$h6-font-size: 0.75rem !default; // 12px

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  sm: $font-size-sm,
  lg: $font-size-lg
) !default;

$headings-margin-bottom: 0.5rem !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1 !default;

$display-font-sizes: (
  1: 4.375rem,
  // 70px
  2: 3.8125rem,
  // 61px
  3: 3rem,
  // 48px
  4: 2.6875rem // 43px
) !default;

$display-font-weight: $font-weight-normal !default;

$lead-font-size: 1.3125rem !default;
$lead-font-weight: $font-weight-normal !default;

$small-font-size: 88.2% !default;

$blockquote-font-size: 1.25rem !default;
$blockquote-footer-color: var(--#{$prefix}gray-600) !default;
$blockquote-footer-font-size: $font-size-xs !default;

$hr-color: var(--#{$prefix}border-color) !default;
$hr-opacity: 1 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-y: 1.5rem !default;
$table-cell-padding-x: 2rem !default;

$table-bg: var(--#{$prefix}white) !default;

$table-striped-bg: var(--#{$prefix}gray-100) !default;

$table-active-bg: var(--#{$prefix}gray-100) !default;

$table-hover-bg: var(--#{$prefix}gray-100) !default;

$table-border-color: var(--#{$prefix}border-color) !default;

$table-group-seperator-color: inherit !default;

$table-bg-scale: -90% !default;

$table-variants: (
  "primary": shift-color($primary, $table-bg-scale),
  "secondary": shift-color($secondary, $table-bg-scale),
  "success": shift-color($success, $table-bg-scale),
  "info": shift-color($info, $table-bg-scale),
  "warning": shift-color($warning, $table-bg-scale),
  "danger": shift-color($danger, $table-bg-scale),
  "light": $light,
  "dark": $gray-800
) !default;

$table-head-bg: var(--#{$prefix}table-bg) !default; // NEW
$table-head-color: var(--#{$prefix}body-color) !default; // NEW

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.8125rem !default;
$input-btn-padding-x: 1.25rem !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0 !default;
$input-btn-focus-color: transparent !default;
$input-btn-focus-box-shadow: 0 0 !default;

$input-btn-padding-y-sm: 0.5625rem !default;
$input-btn-padding-x-sm: 1rem !default;
$input-btn-font-size-sm: $font-size-base !default;
$input-btn-line-height-sm: $line-height-base !default; // NEW

$input-btn-padding-y-lg: 1.122rem !default;
$input-btn-padding-x-lg: 1.5rem !default;
$input-btn-font-size-lg: $font-size-base !default;
$input-btn-line-height-lg: $line-height-base !default; // NEW

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: $font-weight-bold !default;
$btn-box-shadow: 0 0 !default;
$btn-active-box-shadow: 0 0 !default;

$btn-border-radius-sm: var(--#{$prefix}border-radius) !default;
$btn-border-radius-lg: var(--#{$prefix}border-radius) !default;

// Forms

$input-bg: var(--#{$prefix}white) !default;

$input-color: var(--#{$prefix}body-color) !default;
$input-border-color: var(--#{$prefix}border-color) !default;
$input-border-width: $border-width !default;
$input-box-shadow: 0 0 !default;

$input-border-radius-sm: var(--#{$prefix}border-radius) !default;
$input-border-radius-lg: var(--#{$prefix}border-radius) !default;

$input-focus-border-color: var(--#{$prefix}primary) !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $body-secondary-color !default;

$form-check-input-active-filter: none !default;

$form-check-padding-start: 1.5rem !default;
$form-check-label-cursor: pointer !default;

$form-check-input-bg: var(--#{$prefix}gray-300) !default;
$form-check-input-border: transparent !default;
$form-check-input-border-radius: var(--#{$prefix}border-radius) !default;
$form-check-input-focus-border: $form-check-input-border !default;
$form-check-input-focus-box-shadow: 0 0 !default;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' strokeLinecap='round' strokeLinejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color: $component-active-color !default;

$form-switch-color: $white !default;
$form-switch-width: 2.5rem !default;
$form-switch-padding-start: $form-switch-width + 0.5rem !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;

$form-switch-focus-color: $form-switch-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color: $form-switch-color !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;

$input-group-addon-color: $input-placeholder-color;
$input-group-addon-bg: $input-bg !default;

$form-select-indicator-padding: 1.25rem !default;
$form-select-bg-size: 1em 1em !default;
$form-select-indicator-color: $gray-400 !default;
$form-select-indicator: url("data:image/svg+xml,<svg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L4.25 4.25L7.5 1' stroke='#{$form-select-indicator-color}' stroke-width='1.08333' strokeLinecap='round' strokeLinejoin='round'/></svg>") !default;

$form-select-box-shadow: $input-box-shadow !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-box-shadow: $input-focus-box-shadow !default;

$form-file-button-bg: $light !default;

$form-floating-label-opacity: 1 !default;
$form-floating-label-transform: translateY(-0.5rem) !default;

$form-group-margin-bottom: 1rem !default; // NEW

// Navbar

$navbar-padding-y: 1.25rem !default;
$navbar-padding-x: 0 !default;

$navbar-nav-link-padding-x: 1.5rem !default;

$navbar-brand-font-size: 1.5rem !default;
$navbar-brand-margin-end: 0 !default;

$navbar-dark-color: var(--#{$prefix}white) !default;
$navbar-dark-hover-color: var(--#{$prefix}white) !default;
$navbar-dark-active-color: var(--#{$prefix}white) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$white}' strokeLinecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: transparent !default;

$navbar-light-color: var(--#{$prefix}gray-700) !default;
$navbar-light-hover-color: var(--#{$prefix}primary) !default;
$navbar-light-active-color: var(--#{$prefix}primary) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$gray-700}' strokeLinecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: transparent !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 14rem !default;
$dropdown-padding-y: 1.625rem !default;
$dropdown-spacer: 0 !default;
$dropdown-font-size: $font-size-sm !default;
$dropdown-border-width: 0px !default;
$dropdown-box-shadow: $box-shadow !default;

$dropdown-link-color: var(--#{$prefix}gray-700) !default;
$dropdown-link-hover-color: var(--#{$prefix}primary) !default;
$dropdown-link-hover-bg: none !default;

$dropdown-link-active-color: var(--#{$prefix}primary) !default;
$dropdown-link-active-bg: none !default;

$dropdown-item-padding-y: 0 !default;
$dropdown-item-padding-x: 1.75rem !default;

$dropdown-header-color: var(--#{$prefix}primary) !default;

// Pagination

$pagination-padding-y: $input-btn-padding-y !default;
$pagination-padding-x: $input-btn-padding-x !default;
$pagination-padding-y-sm: $input-btn-padding-y-sm !default;
$pagination-padding-x-sm: $input-btn-padding-x-sm !default;
$pagination-padding-y-lg: $input-btn-padding-y-lg !default;
$pagination-padding-x-lg: $input-btn-padding-x-lg !default;

$pagination-border-color: var(--#{$prefix}gray-300) !default;

$pagination-focus-box-shadow: 0 0 !default;

$pagination-hover-bg: var(--#{$prefix}gray-200) !default;
$pagination-hover-border-color: var(--#{$prefix}pagination-border-color) !default;

$pagination-disabled-bg: var(--#{$prefix}pagination-bg) !default;
$pagination-disabled-border-color: var(--#{$prefix}pagination-border-color) !default;

$pagination-line-height: $line-height-base !default; // NEW

// Cards

$card-spacer-y: 2rem !default;
$card-spacer-x: 2rem !default;
$card-title-spacer-y: 1.5rem !default;
$card-border-width: 0px !default;
$card-border-color: var(--#{$prefix}border-color) !default;
$card-cap-padding-y: 1.5rem !default;

// Accordion

$accordion-padding-y: 1.5rem !default;
$accordion-padding-x: 2rem !default;
$accordion-border-color: var(--#{$prefix}border-color) !default;

$accordion-button-active-bg: var(--#{$prefix}body-bg) !default;
$accordion-button-active-color: var(--#{$prefix}body-color) !default;
$accordion-button-focus-box-shadow: 0 0 !default;

$accordion-icon-width: 0.85rem !default;
$accordion-icon-color: $body-secondary-color !default;
$accordion-icon-active-color: $accordion-icon-color !default;

// Popovers

$popover-max-width: 380px !default;
$popover-border-color: transparent !default;
$popover-border-radius: var(--#{$prefix}border-radius) !default;
$popover-box-shadow: $box-shadow-lg !default;

$popover-header-bg: transparent !default;
$popover-header-padding-y: 1.5rem !default;
$popover-header-padding-x: 2rem !default;

$popover-body-color: $body-secondary-color !default;
$popover-body-padding-y: 1.5rem !default;
$popover-body-padding-x: 2rem !default;

$popover-arrow-width: 1.25rem !default;
$popover-arrow-height: 0.625rem !default;

// Toasts

$toast-padding-y: 0.5rem !default;

// Badges

$badge-padding-y: 0.25rem !default;
$badge-padding-x: 0.75rem !default;

$border-radius-pill-padding-x: var(--#{$prefix}badge-padding-x) !default; // NEW

// Modals

$modal-inner-padding: 2.5rem !default;

$modal-backdrop-opacity: 0.8 !default;

// Alerts

$alert-padding-y: 0.75rem !default;
$alert-padding-x: 1.25rem !default;

// List group

$list-group-border-color: var(--#{$prefix}border-color) !default;
$list-group-item-padding-y: 1.5rem !default;
$list-group-item-padding-x: 2rem !default;

// Figures

$figure-caption-font-size: $small-font-size !default;
$figure-caption-color: $body-secondary-color !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.75rem !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-divider-color: var(--#{$prefix}gray-700) !default;
$breadcrumb-active-color: var(--#{$prefix}gray-700) !default;
$breadcrumb-border-radius: 0 !default;

// Close

$btn-close-width: 0.5em !default;
$btn-close-color: $body-secondary-color !default;
$btn-close-focus-shadow: none !default;

// Code

$code-font-size: 0.8125rem !default;

//
// Theme ===================================
//

// Paths

$path-to-img: "../../img" !default;
$path-to-fonts: "../../fonts" !default;

// Typography

$headings-letter-spacing: -0.01em !default;

$h1-line-height: 1.3 !default;
$h2-line-height: 1.35 !default;
$h3-line-height: 1.4 !default;
$h4-line-height: 1.45 !default;
$h5-line-height: 1.5 !default;
$h6-line-height: 1.55 !default;

$display-line-height: 1.2 !default;
$display-letter-spacing: -0.02em !default;

$h1-font-size-md: 1.875rem !default; // 30px
$h2-font-size-md: 1.5rem !default; // 24px
$h3-font-size-md: 1.125rem !default; // 18px
$h4-font-size-md: 1.0625rem !default; // 17px

$display1-font-size-md: 3.125rem !default; // 48px
$display2-font-size-md: 2.5rem !default; // 38px
$display3-font-size-md: 2.25rem !default; // 36px
$display4-font-size-md: 2rem !default; // 32px

$font-size-lg-md: 1.0625rem !default; // 17px
$lead-font-size-md: 1.1875rem !default; // 19px

$blockquote-small-font-weight: $font-weight-bold !default;
$blockquote-small-letter-spacing: 0.07em !default;

$letter-spacing-lg: 0.08em !default;

// Buttons + Forms

$input-btn-padding-y-xs: 0.25rem !default;
$input-btn-padding-x-xs: 0.625rem !default;
$input-btn-font-size-xs: $font-size-sm !default;
$input-btn-line-height-xs: $line-height-base !default;

// Buttons

$btn-subtle-bg-opacity: 0.1 !default;

$btn-line-height-sm: $input-btn-line-height-sm !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;

// Forms

$input-line-height-sm: $input-btn-line-height-sm !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-padding-y-xs: $input-btn-padding-y-xs !default;
$input-padding-x-xs: $input-btn-padding-x-xs !default;
$input-font-size-xs: $input-btn-font-size-xs !default;
$input-line-height-xs: $input-btn-line-height-xs !default;
$input-height-xs: calc(
  #{$input-btn-padding-y-xs * 2} + #{$input-btn-font-size-xs} * #{$input-line-height-xs} + #{$input-border-width * 2}
);

$form-check-input-bg-dark: rgba($white, 0.2) !default;
$form-check-input-checked-bg-color-dark: var(--#{$prefix}white) !default;

$form-switch-min-height: 1.375rem !default;

$form-switch-color-dark: $white !default;
$form-switch-focus-color-dark: $white !default;
$form-switch-checked-color-dark: $primary !default;

$form-switch-bg-image-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>") !default;
$form-switch-focus-bg-image-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color-dark}'/></svg>") !default;
$form-switch-checked-bg-image-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color-dark}'/></svg>") !default;

$form-select-padding-y-xs: $input-padding-y-xs !default;
$form-select-padding-x-xs: $input-padding-x-xs !default;
$form-select-font-size-xs: $input-font-size-xs !default;
$form-select-line-height-xs: $input-line-height-xs !default;
$form-select-height-xs: $input-height-xs !default;
$form-select-indicator-padding-xs: 0.625rem !default;
$form-select-bg-size-xs: 0.75em !default;

$form-floating-label-font-size: $font-size-xs !default;
$form-floating-label-color: $input-placeholder-color !default;

// Navbar

$navbar-brand-font-weight: $font-weight-bold !default;

$navbar-nav-link-font-weight: $font-weight-bold !default;

$navbar-nav-item-spacing: 1.5rem !default;

$navbar-dropdown-toggle-margin-bottom: 1.5rem !default;

// Dropdowns

$dropdown-item-padding-x-lg: 2.5rem !default;
$dropdown-item-spacing-y: 0.45rem !default;

$dropdown-padding-x: var(--#{$prefix}dropdown-item-padding-x) !default;
$dropdown-padding-x-xs: 1.25rem !default;
$dropdown-padding-y-xs: 1rem !default;
$dropdown-padding-x-lg: $dropdown-item-padding-x-lg !default;
$dropdown-padding-y-lg: 2.375rem !default;

$dropdown-min-width-xs: 0 !default;
$dropdown-min-width-md: 22rem !default;
$dropdown-min-width-lg: 35rem !default;
$dropdown-min-width-xl: 42rem !default;

// Card

$card-meta-spacer-y: 1.5rem !default;

$card-row-spacer-y: 4rem !default;
$card-row-spacer-x: 2.5rem !default;

// Popover

$popover-header-font-size: $h6-font-size !default;
$popover-header-color: var(--#{$prefix}primary) !default;

// Badges

$badge-padding-y-lg: 0.7em !default;
$badge-padding-x-lg: 1.15em !default;

// Breadcrumbs

$breadcrumb-font-size: $font-size-sm !default;

// Code

$code-line-height: 1.35 !default;

// Avatar

$avatar-size-base: 1.875rem !default;
$avatar-size-xs: 1rem !default;
$avatar-size-sm: 1.5rem !default;
$avatar-size-lg: 2.25rem !default;
$avatar-size-xl: 4rem !default;
$avatar-size-xxl: 5rem !default;

$avatar-title-bg: var(--#{$prefix}gray-500) !default;
$avatar-title-color: var(--#{$prefix}white) !default;

// Icons

$icon-size-base: 3rem !default;
$icon-size-xs: 1.5rem !default;
$icon-size-sm: 2.25rem !default;
$icon-size-lg: 4rem !default;
$icon-size-xl: 5rem !default;

$icon-circle-size: 4.1875rem !default;
$icon-circle-font-size: 1.875rem !default;

// Images

$img-skewed-rotate-y: 35deg !default;
$img-skewed-rotate-x: 15deg !default;

// List

$list-item-spacing-y: 0.35rem;

$list-link-font-size: $font-size-sm !default;
$list-link-color: var(--#{$prefix}gray-700) !default;
$list-link-hover-color: var(--#{$prefix}primary) !default;

// Screenshot

$screenshot-border-radius: 0.625rem !default;
$screenshot-box-shadow:
  25px 60px 125px -25px fade-out($gray-700, 0.9),
  16px 40px 75px -40px fade-out(black, 0.8) !default;

$modal-xl: 1200px;

$modal-fade-transform: translate3d(0, 40px, 0);
