//
// user.scss
// Use this to write your custom SCSS
//

.financiadores img {
  height: 32px;
}

[class^="menu-container"] {
  position: relative;
  z-index: 1;
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  transition: all 0.2 ease-in;
}
@media (min-width: 1200px) {
  .menu-container {
    max-width: 1200px;
  }
}

.pagetitle {
  color: var(--Text-Dark-Primary, #2d3142);
  /* Headings/H1 */
  font-family: Poppins;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @include media-breakpoint-down(lg) {
    font-size: 34px;
  }

  @include media-breakpoint-down(md) {
    font-size: 28px;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    position: fixed;
    top: 1rem;
    left: 1rem;
    height: auto;
    max-height: calc(100% - 2rem) !important;
    width: calc(100% - 2rem);
    background-color: rgba(255, 255, 255, 0.82);
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 10px;
    padding: 10px 0;
    backdrop-filter: blur(10px) !important;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1) !important;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse.show {
    opacity: 1;
    transform: scale(1);
    top: 110px;
    min-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse.collapsing {
    opacity: 0;
    transform: scale(0.9);
    top: 110px;
    min-height: 64px;
  }
}

.btn {
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

@media (min-width: 991px) {
  .navbar-toggler-icon {
    display: none;
  }
}

@media (max-width: 959px) {
  .navbar-toggler-icon {
    display: inline-block;
  }
}

.navbar-nav .nav-link {
  color: var(--Primary-Blue-D, #243351);
  text-align: center;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.photo-attribution {
  position: absolute;
  bottom: 5px;
  font-size: 10px;
  color: white;
  right: 10px;
  a {
    color: white;
  }
  opacity: 0.6;
}

.article-list {
  .article-date {
    color: var(--Text-Dark-Primary, #2d3142);
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .button-group {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  .article-link {
    color: var(--Text-Dark-Primary, #2d3142);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    gap: 4px;

    a {
      justify-content: center;
      align-items: center;
      gap: 4px;
      display: flex;

      span {
        text-decoration: underline;
      }

      &:hover {
        svg {
          stroke: #db6d68;
        }
        span {
          text-decoration: none;
        }
      }
    }
  }

  svg {
    stroke: var(--Text-Dark-Primary, #2d3142);
  }
}

ul.article-single-list {
  color: var(--Text-Dark-Primary, #2d3142);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: -0.16px;

  span.title-list {
    color: var(--Text-Dark-Primary, #2d3142);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
    letter-spacing: -0.16px;
  }
}

.nav-link a {
  min-height: 60px;
  color: #2d3f64;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.nav-link {
  .wrap {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 4px;
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}
.nav-link {
  &:hover {
    &:after {
      width: 100%;
      position: absolute;
      content: "";
      margin: 0 auto;
      width: 0;
      height: 2px;
      transition: all 0.3s ease;
      right: 0;
      left: 0;
      pointer-events: none;
      background-color: #344974;
    }
  }
}

.custom.btn.btn-link {
  font-family: "Poppins";
  color: var(--bs-link-hover-color);
  font-weight: 600;
  padding-right: 0;
  padding-left: 0;
  font-size: 16px;
  &:hover {
    color: #e0807b;
    text-decoration: none;
  }
}

.navbar .logo-holder .navbar-brand {
  height: 85px;
  display: inline-block;
  position: relative;
  transition: all 0.5s ease-out;
}

@media (min-width: 577px) {
  .scrollnav .container {
    border-radius: 20px;
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-out;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .scrollnav .logo-holder.container {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.logo-holder.container {
  background-color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 576px) {
  .logo-holder.container {
    background-color: rgba(255, 255, 255, 1);
  }
}

.logo {
  height: 120px;
  position: absolute;
  top: 50%;
  margin-top: -52px;
}

.logo-scroll {
  height: 67px;
  position: absolute;
  top: 50%;
  margin-top: -26px;
}

@media (max-width: 576px) {
  .logo {
    display: none;
  }

  .logo-scroll {
    animation: 0.5s ease-in-out opacityanimate100 forwards;
    opacity: 1;
  }
}

@media (min-width: 576px) {
  .logo {
    animation: 0.5s ease-in-out 0.5s 1 opacityanimate100 forwards;
  }

  .logo.scrolled {
    animation: 0.2s ease-in-out opacityanimate0 forwards;
  }

  .logo-scroll {
    animation: 0.2s ease-in-out 0.5s opacityanimate0 forwards;
  }

  .logo-scroll.scrolled {
    animation: 0.5s ease-in-out opacityanimate100 forwards;
  }
}

@keyframes opacityanimate100 {
  100% {
    opacity: 1;
  }
}

@keyframes opacityanimate0 {
  100% {
    opacity: 0;
  }
}

.custom-badge span {
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

@keyframes slideInFromBottom {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.modal-article {
  display: flex !important;
  align-items: flex-end;

  .modal-dialog {
    margin-bottom: 0;
    animation: slideInFromBottom 0.5s ease-out;
  }

  .container {
    padding: 0;
  }

  .modal-content {
    border-radius: 40px 40px 0px 0px;
    align-self: flex-end;
  }
  .btn-close {
    background: transparent
      url('data:image/svg+xml;utf8,<svg width="39" height="39" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke="%232D3142" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"><path d="M10.205 28.795c5.272 5.272 13.818 5.272 19.09 0 5.27-5.271 5.27-13.818 0-19.089-5.272-5.271-13.818-5.271-19.09 0-5.27 5.271-5.27 13.818 0 19.09ZM24.523 14.479l-9.544 9.544M14.977 14.479l9.544 9.544"/></g></svg>')
      center / 30px auto no-repeat;
    top: 1.8rem;
    right: 1.8rem;
    opacity: 1;
  }

  .h3 {
    color: var(--Text-Dark-Primary, #2d3142);
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h2 {
    color: var(--Text-Dark-Primary, #2d3142);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    span {
      color: var(--Text-Dark-Secondary, #52596e);
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 27.2px */
      letter-spacing: -0.16px;
    }
  }

  p {
    color: var(--Text-Dark-Secondary, #52596e);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 27.2px */
    letter-spacing: -0.16px;
  }

  ul.authors {
    &.two-columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    &.single-column {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      color: var(--Text-Dark-Secondary, #52596e);
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 27.2px */
      letter-spacing: -0.16px;
    }
  }
}

.nav-brand-text {
  display: flex;
  height: 63px;
  padding-left: 30px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-left: 180px;
  color: var(--Primary-Blue-D, #243351);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-left: 2px solid var(--Primary-Blue-D, #243351);
  min-width: 50%;

  a {
    position: relative;
    text-decoration: underline;

    &:hover {
      color: #d65853;
      text-decoration: none;
    }
  }

  @include media-breakpoint-down(md) {
    font-size: 14px;
    min-width: 40%;
    padding-left: 20px;
    margin-left: 170px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 12px;
    min-width: 40%;
    padding-left: 20px;
    margin-left: 170px;
  }
}

.page-link:hover {
  z-index: 2;
  color: #d65853;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}

.page-link {
  background-color: transparent;
  height: 41.87px;
  line-height: 1.4 !important;
  border: none;
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: transparent;
  border-color: #d65853;
  border-bottom: 2px solid;
  color: #d65853;
}

.page-link.disabled,
.disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: transparent;
  border-color: transparent;
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.categories-btn {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 2px solid var(--Primary-Red-D, #d65852);

  &.btn {
    color: var(--Text-Dark-Primary, #2d3142);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;

    &.nohover {
      pointer-events: none;
    }

    &:hover {
      background-color: rgb(252, 240, 240);
      color: #2c3f65;
    }

    &:active {
      background-color: rgb(252, 240, 240);
      color: #2c3f65;
    }

    span.badge.bg-primary {
      color: var(--Text-Light-Primary, #fff);
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      border-radius: 50px;
      padding: 2px 10px;
      top: 0;
      margin: 3px 0;
    }
  }
}

.categories-list,
.sources-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.sources-list {
  .categories-btn.btn {
    color: #d65852;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    svg {
      stroke: #d65852;
      margin-top: -3px;
    }
  }
}

.source-query{
  .categories-btn.btn {
    color: #2d3f64;
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 11px;
    font-weight: 700;
    pointer-events:none;
  }
  small{
    font-size: 11px;
    font-weight: 800;
    color: #2d3f64;
  }
  .andseparador{
    font-weight: 800;
    color: #2d3f64;  
  }
}


.sources{
  h3{
      font-size:24px;
      color: #2d3f64;
  }
}