//
// background.scss
// Extended from Bootstrap
//

.bg-cover {
  background: no-repeat center center / cover;
}


// Between

.bg-between {

  @include media-breakpoint-down(lg) {
    background-image: none !important;
  }

  @include media-breakpoint-up(lg) {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 70%;
  }
}


// Gradients

.bg-gradient-light-white {
  background-image: linear-gradient(to bottom, var(--#{$prefix}light) 0%, var(--#{$prefix}white) 100%);
}

.bg-gradient-dark-black {
  background-image: linear-gradient(to bottom, var(--#{$prefix}dark) 0%, var(--#{$prefix}black) 100%);
}

.bg-gradient-multicolor {
  background-image: conic-gradient(from 52deg at 50% 50%,
      #8024A0 0deg,
      #31EEC1 33deg,
      #6924A0 222deg,
      #D6723B 264deg,
      #D5498C 295deg,
      #8024A0 360deg);
}

.footer{
  .top-footer{
    background-color: #344974;

    .container{
      max-width: 1200px;
      margin: 0 auto;
    }
    .logo-footer{
      width: auto;
      height: 120px;
      position: relative;
    }
    ul>li>.wrap{

      &:after{
      position: absolute;
      content: "";
      margin: 0 auto;
      width: 0;
      height: 2px;
      transition: all .3s ease;
      right: 0;
      left: 0;
      pointer-events: none;
      background-color: #db6d68;
      }
    }
    .sub-menu .wrap{

      &:after {
      position: absolute;
      content: "";
      margin: 0 auto;
      width: 0;
      height: 2px;
      transition: all .3s ease;
      right: 0;
      left: 0;
      pointer-events: none;
      background-color: #db6d68;
      }
    }
    .footer-menu{
      li{
        margin-bottom: 0.5rem;

        a{
          color: #ffffff;
          font-family: "Poppins", sans-serif;
          font-size:16px;
          line-height: 1.45;
          font-weight: 600;
        }
      }
    }
    .nav-ul {
        justify-content: flex-start;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        margin-right: calc (20px / 2* -1);
        margin-left: calc (20px / 2* -1);
    }
  }
  .bottom-footer{
    background-color: #243351;
    .container{
      max-width: 1200px;
      margin: 0 auto;
      p{
        color: rgb(255, 255, 255);
        display: flex;
        font-family: "Lato";
        font-size: 12px;
        font-weight: 600;
        max-height: 19.1953px;
        line-height: 19.2px;
        text-align: left;
        margin: 8px 0;
        @include media-breakpoint-down(md) {
          justify-content: center;
      }
      }
      p.credits{
        justify-content: end;
        @include media-breakpoint-down(md) {
             justify-content: center;
         }
      }
      a{
        text-decoration: underline;
        color: rgb(255, 255, 255);
        transition: all .2s ease-in;
        &:hover{
          text-decoration: none;
          opacity: .8;
        }
      }
    }
  }
}

.full-width-background {
  position: relative;
  background-color: rgb(252, 240, 240);
}

// Patterns

//.bg-pattern-2 {
  //background-image: linear-gradient(to bottom, transparent 0%, var(--#{$prefix}black) 100%), url('#{$path-to-img}/patterns/pattern-2.png');
//}
